
import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { changePassword } from '../../actions/user'

const ChangePassword = (props) => {
  const { selectMenu } = props

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordCheck, setNewPasswordCheck] = useState('')

  const handleChangePassword = async (event) => {
    event.preventDefault()
    const result = await changePassword(currentPassword, newPassword, newPasswordCheck)
    if (result) {
      selectMenu("Profile")
    }
  }
  

  return (
    <form onSubmit={(event) => {handleChangePassword(event)}}>
      <Form.Group className="mb-3" controlId="formCurrentPassword">
        <Form.Label>Current Password</Form.Label>
        <Form.Control type="password" placeholder="Current Password" onChange={(event) => setCurrentPassword(event.target.value)} />
      </Form.Group>
    
      <Form.Group className="mb-3" controlId="formNewPassword">
        <Form.Label>New Password</Form.Label>
        <Form.Control type="password" placeholder="New Password" onChange={(event) => setNewPassword(event.target.value)} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formConfirmPassword">
        <Form.Label>New Password Again</Form.Label>
        <Form.Control type="password" placeholder="New Password again" onChange={(event) => setNewPasswordCheck(event.target.value)} />
      </Form.Group>

      <Button className="neutralButton" variant="primary" type="submit">
        Save
      </Button>
    </form>
  )
}

export default ChangePassword