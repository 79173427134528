import { collectionHandler } from "./collections"
import { query, where, orderBy, getDocs, runTransaction, doc } from "firebase/firestore";
import { enumeration } from '../constants/enumeration';
import { db } from "../firebase";
import { getGeohashRange } from "../helpers/geoHashing";
import { comparisonTime } from "../helpers/timeHandler";
import { isCustomerBookable } from "../helpers/availabilities"
import { availabilityMinuteComparison } from '../app/settings'
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const bookingCaller = httpsCallable(functions, 'bookingCaller');
const {
    RESTAURANT,
    CONFIRMED,
    PENDING
} = enumeration;

// Can be used to pass query to snapshot listener
export const getRestaurantAvailabilityQuery = (lat : number, lng : number) => {

    const range = getGeohashRange(lat,lng)
    const minuteComparison = availabilityMinuteComparison
    try {
        const availQuery = query(
            collectionHandler('primaryInteractions'),
            where('interactionType', '==', 'restaurantBeacon'),
            where('customerID', '==', null),
            where('status', '==', PENDING),
            where('geoHash', 'in', range),
            where('time', '>=', comparisonTime(minuteComparison)),
            orderBy('time')
        )
    return availQuery
    }
    catch (error) {
        console.log('availabilityquery');
        console.log(error)
    }
}

export const getRestaurantAvailabilities = async (lat : number, lng : number) => {

    const availQuery = getRestaurantAvailabilityQuery(lat, lng)

    const availSnapshot = await getDocs(availQuery)

    return availSnapshot.docs
}

// for snapshot listener
export const getRestaurantAvailabilitiesbyPlaceIDQuery = (placeID: string) => {

    const minuteComparison = availabilityMinuteComparison

    const availQuery = query(
        collectionHandler('primaryInteractions'),
        where('interactionType', '==', 'restaurantBeacon'),
        where('customerID', '==', null),
        where('status', '==', PENDING),
        where('placeID', '==', placeID),
        where('time', '>=', comparisonTime(minuteComparison)),
        orderBy('time')
    )

    return availQuery
}

export const book = async (primaryInteractionKey : string, primaryInteraction : any, user : any, customerChairs : number, firstName: string,
    lastName: string, email: string, phoneNumber : string ) =>{
    const updateData = {
        animatesFor: RESTAURANT,
        status: CONFIRMED,
        customerName: user.name,
        customerID: user.uid,
        email:email,
        customerRating: 100,
        chairs: customerChairs,
        bookingTime: new Date(),
        originalChairs: primaryInteraction.originalChairs ? primaryInteraction.originalChairs : primaryInteraction.chairs
    };
    try {
         if (primaryInteraction.outsideSource && primaryInteraction.outsideSource === 'TableOnline') {
         //   console.log('in tableonline :D');
                const userData = {
                    phone: phoneNumber,
                    firstName : firstName,
                    lastName : lastName,
                    email : email 

                } 
                let res = {};
                const res2 =  await bookingCaller({
                    apiID: primaryInteraction.outsideID,
                    chairs: customerChairs,
                    time: primaryInteraction.time.toDate().getTime(),
                    uData: userData
                })
                      .then( async (result) => {
                        console.log(result);
                          if (result.data === 'uDataError' || result.data === 'bookingError' || result.data === 'validationError')
                            {
                                throw new Error("There seems to be some issues with our booking information")
                            }
                          else if (result.data === 'fullError' || result.data === 'availabilityError' ||result.data === 'matchError')
                            {
                                throw new Error("Table has been grabbed already!")
                            }
                          else  //did not hit any errors, update availability
                            {
                              const updater=  await runTransaction(db, async (transaction) => {
                            //        console.log('did go here');
                                    const availabilityRef = doc(collectionHandler('primaryInteractions'),primaryInteractionKey)
                        
                                    const availabilityDoc = await transaction.get(availabilityRef);
                                    if (!availabilityDoc.exists()) {
                                        throw new Error("Availability does not exist!")
                                    }
                        
                                    if (!isCustomerBookable(availabilityDoc.data())) {
                                        throw new Error("Availability not available anymore!")
                                    }
                                
                                    transaction.update(availabilityRef, updateData);
                                    return true;
                                });
                                return updater;
                    
                            }
                      })
                  .catch((error) => {
                   //     console.log('res',error)
                        alert(error);
                  //      console.log(error);
                        return false;
                      });
                      //did not hit any errors, assume correct
                      return true;
         } //tableonline-specific part ends here
        else {
        const updatePrimary = await runTransaction(db, async (transaction) => {
          //  console.log('did go here');
            const availabilityRef = doc(collectionHandler('primaryInteractions'),primaryInteractionKey)

            const availabilityDoc = await transaction.get(availabilityRef);
            if (!availabilityDoc.exists()) {
                throw new Error("Availability does not exist!")
            }

            if (!isCustomerBookable(availabilityDoc.data())) {
                throw new Error("Availability not available anymore!")
            }
      //      console.log('noerrors');
            transaction.update(availabilityRef, updateData);

            return true;
        });
        if(updatePrimary ===true){
            return true;
        }
        else {
            return false;
        }
        }
    }
    catch (e) {
      //  console.log('came to the end for error');
        alert(e);
        return false
    }
//     alert("Table booked!")
}