import Form from 'react-bootstrap/Form'

const NumberField = (props) => {
    let { title, defaultValue, min, max, step, onChange } = props

    min = min ? min : 1
    step = step ? step : 1

    return (
        <Form.Group>
            <Form.Label>{title}</Form.Label>
            <Form.Control className="mb-3" type={"number"} defaultValue={defaultValue} min={min} max={max} step={step} onChange={onChange}/>
        </Form.Group>
    )
}

export default NumberField