import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import LoginOptionView from './LoginOptionView';
import EmailLoginView from './EmailLoginView';
import ArrowBack from '@mui/icons-material/ArrowBack'
import EmailRegistrationView from './EmailRegistrationView';

const LoginView = (props) => {
    const { onHide, show } = props

    const [currentView, setCurrentView] = useState("loginOptions")

    const closeWindow = () => {
        setCurrentView("loginOptions")
        onHide()
    }

    let title = 'Log in'
    let renderedView =  <LoginOptionView updateView={setCurrentView} close={closeWindow}/>
    let backButton = <ArrowBack style={{cursor: "pointer"}} onClick={() => setCurrentView("loginOptions")}/>

    if (currentView === "loginOptions") {
        backButton = null
    }

    if (currentView === "email") {
        renderedView = <EmailLoginView close={closeWindow} updateView={setCurrentView}/>
    }

    if (currentView === "emailRegistration") {
        renderedView = <EmailRegistrationView close={closeWindow}/>
        title = 'Register'
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {backButton}
                {renderedView}
            </Modal.Body>
        </Modal>
    )
}

export default LoginView;