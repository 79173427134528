import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import { emailLogin } from '../../actions/user'
import { login } from '../../reducers/userSlice'
import { useAppDispatch } from '../../app/hooks';

const EmailLoginView = (props) => {

  const { close, updateView } = props

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useAppDispatch()

  const handleLogin = async (event, email: string, password: string) => {
    event.preventDefault();

    const user = await emailLogin(true, email, password)

    if (user) {
      delete user.creationDate

      dispatch(login(user))

      close()
    }

  }
  
  return (
    <>
      <form onSubmit={(event) => {handleLogin(event, email, password)}}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email" onChange={(event) => setEmail(event.target.value)} />
        </Form.Group>
      
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Password" onChange={(event) => setPassword(event.target.value)} />
        </Form.Group>
        <button className='neutralButton' type="submit">
            Log in
        </button>
      </form>
      <p>Not a user yet? <u style={{cursor: 'pointer'}} onClick={() => updateView("emailRegistration")}>Register here!</u></p>
    </>
  )
}

export default EmailLoginView