import { getMapIcon } from '../../helpers/availabilities'

require('../../styles/Restaurants.css')

const MapAvailability = (props) => {
    const { count } = props

    const icon = getMapIcon(count)

    return (
        <img src={icon} className={"Restaurant-Map-Item"} alt="map restaurant" /> 
    )
}

export default MapAvailability