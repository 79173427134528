import { createSlice } from '@reduxjs/toolkit'

export const loginPopupSlice = createSlice({
  name: 'loginPopup',
  initialState: {
    show: false
  },
  reducers: {
    showLoginPopup: (state) => {
      state.show = true
    },
    hideLoginPopup: (state) => {
      state.show = false
    }
  },
})

// Action creators are generated for each case reducer function
export const { showLoginPopup, hideLoginPopup } = loginPopupSlice.actions

export default loginPopupSlice.reducer