import { toHours } from '../../helpers/timeHandler'

const OpeningHours = (props) => {
    const { openingHours } = props

    if (!openingHours) {
        return null
    }
    
    return (
        <div>
            <h5>Opening Hours:</h5>

            <table className="Opening-Hours">
                <tbody>
                    {openingHours.map((item) => {
                        return (
                            <tr key={item.day}>
                                <td><b>{item.day}</b></td>
                                <td>{item.isOpen ? `${toHours(item.opens)} - ${toHours(item.closes)}` : "Closed"}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default OpeningHours