import geohash from "ngeohash";

/**
 * Translates lat/lng coordinates to GeoHash range which can be used to firebase distance based queries
 * @param latitude Latitude of the center point
 * @param longitude Longitude of the center point
 * @param distance Distance in kilometres around the center point (miles)
 * @returns lower Lower limit Geohash
 * @returns upper Upper limit Geohash
 */
export const getGeohashRange = (latitude: number, longitude: number) => {
    const lat = 0.0144927536231884; // degrees latitude per mile
    const lon = 0.0181818181818182; // degrees longitude per mile
    const kmMiles = 1.60934;
    const distance = 17

    const lowerLat = latitude - lat * distance / kmMiles;
    const lowerLon = longitude - lon * distance / kmMiles;

    const upperLat = latitude + lat * distance / kmMiles;
    const upperLon = longitude + lon * distance / kmMiles;

    /**
     * Creates a list of geohash strings based on provided coordinates. -JH
     * The size of each geohash is determined by the length of the string, in this case 4.
     * Should provide accuracy in the range of tens of kilometers.
     */
    const hashList = geohash.bboxes(lowerLat, lowerLon, upperLat, upperLon, 4);

    return (
        hashList
    );
};

