
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import userReducer from '../reducers/userSlice'
import loginPopupReducer from '../reducers/loginPopupSlice'
import locationReducer from '../reducers/locationSlice'

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  user: userReducer,
  showLoginPopup: loginPopupReducer,
  location: locationReducer
})


const store = configureStore({
  reducer: rootReducer
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store