import { enumeration } from '../constants/enumeration';

const {
    PENDING
} = enumeration;

export const isCustomerBookable = (availability) => {
    if (!availability) {
        return false
    }
    const {interactionType, customerID, status} = availability
    if (
        interactionType === 'restaurantBeacon' &&
        customerID == null &&
        status === PENDING
    )
    {
        return true
    }
    return false
}

export const formatFilters = (filters) => {
    if (!filters) {
        return null
    }
    return filters.join(", ")
}

export const chairsHasRange = (chairs) => {
    if (!chairs) {
        return false
    }
    if (chairs[0] === chairs[1]) {
        return false
    }
    return true
}

export const formatChairs = (chairs) => {
    if (!chairs) {
        return null
    }
    if (typeof chairs === "number") {
        return chairs
    }
    if (!chairsHasRange(chairs)) {
        return chairs[0]
    }
    return `${chairs[0]}-${chairs[1]}`
}

export const getMapIcon = (count) => {
    if (count > 9) {
        return require('../assets/images/map9plus.png')
    }
    return require(`../assets/images/map${count}x.png`)
}