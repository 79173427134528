const BookButton = (props) => {
  let className = "bookButton"

  if (props.disabled) {
    className = "neutralButton2"
  }
  return (
    <button onClick={props.onClick} className={className} disabled={props.disabled} style={props.style}>
        <b>{props.text}</b>
    </button>
  )
}

export default BookButton