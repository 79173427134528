const userMarkerIcon = require('../assets/images/user_marker.png')

require('../styles/Map.css')

const UserMarker = (props) => {
  return (
    <img src={userMarkerIcon} className={"User-Marker"} alt="user marker" /> 
  )
}

export default UserMarker