const Review = (props) => {
    const { review } = props

    const drawReviewStars = (rating) => {
        let starList = [];
        let color = null

        for (let i = 1; i < 6; i++) {
            if (rating >= i) {
                color = "yonoGreen"
            }
            else {
                color = "lightGrey"
            }
            starList.push(<span key={i} className={color}>● </span>)
        }

        return starList;
    }

    return (
        <div>                        
            <h6>{review.author_name}</h6> 
            <span>{drawReviewStars(review.rating)} {review.rating}</span>
            <p style={{textAlign: "left"}}>{review.text}</p>
            <br></br>
        </div>
    )
}
export default Review