import { toHours, toDate } from '../../helpers/timeHandler'
import { cancelReservation } from '../../db/reservations'

//const gbox = require('../../assets/images/giftbox.png')

const Reservation = (props) => {
  const { reservation, onHide, setSelectedMenu } = props
  
  const { restaurantName, chairs, time, status, badge } = reservation.data()

  console.log(status);
  const reservationId = reservation.id
  // const [badgeIsVisible, setBadgeVisible] = useState(false);
  const starttime = toHours(time)
  const startDate = toDate(time)

//   const setBadgeVisibleTrue =()=>{
//     setBadgeVisible(true)
// }
//   const setBadgeVisibleFalse =()=>{
//       //console.log('avail',availability);
//       setBadgeVisible(false)
//   }
  const cancel = async () => {
      if (await cancelReservation(reservationId, reservation.data())) {
        onHide()
        setSelectedMenu("Pending")
      }
  }
  const  renderSwitch =(statusStr) => {
    switch(statusStr) {
      case 'confirmed History':
        return 'History';
      case 'customer Cancel':
        return 'Cancelled';
      case 'restaurant Cancel':
        return 'Cancelled by restaurant';
      case 'confirmed':
        return 'Coming Up';
      default:
        return '??';
    }
  }
  return (
    <div className="Reservation">
      <h2>Confirmed Booking</h2>
      <h3>{restaurantName}</h3>
      <table className="Reservation-Info">
        <tbody>
          <tr>
            <td>Persons:</td>
            <td>{chairs}</td>
          </tr>
          <tr>
            <td>Date:</td>
            <td>{startDate}</td>
          </tr>
          <tr>
            <td>Time:</td>
            <td>{starttime}</td>
          </tr>
          <tr>
            <td>Status:</td>
            <td>{renderSwitch(status)}</td>
          </tr>
          { 
            badge && badge.length > 0 
            ?
            badge.map((offer, i) => {
              return (
                <tr>
                  <td>Offer #{i}: </td>
                  <td>  {offer.badgeContent}</td>
                </tr>
              )
            })
            :
            null
          }
        </tbody>
      </table>

     { status==='confirmed'?
      <button className='cancelButton' onClick={cancel}>Cancel Booking</button>
      :null
     }
    </div>
  )
}

export default Reservation