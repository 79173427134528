import { getDistanceKilometers } from "../../helpers/location";
//import { formatFilters } from '../../helpers/availabilities';
import { useAppSelector} from '../../app/hooks';
import AvailabilityStartTime from './AvailabilityStartTime';
import AvailabilityPersons from './AvailabilityPersons';
import Distance from '../generic/Distance';
import AvailabilityDate from './AvailabilityDate';
import { useState } from 'react'
import RestaurantDetailsPopup from '../restaurants/RestaurantDetailsPopup';
import "../../styles/AvailabilityDetailsSmall.css"
import "../../styles/Buttons.css"
import BadgePopup from "./BadgePopup";
import BookButton from "./BookButton";

const gbox = require('../../assets/images/giftbox.png')
const AvailabilityDetailsSmall = (props) => {

    let { availability, availabilityId, title, openConfirmModal, presentName } = props
    const { lat, lng, chairs, time } = availability;
  //  console.log(availability);
    const [detailsModalIsOpen, setDetailsModalOpen] = useState(false);
    const [badgeIsVisible, setBadgeVisible] = useState(false);
    const reduxLocation = useAppSelector((state) => state.location)
    
    title = title ? title : "Free Table"

    let distanceKilometers

    if (reduxLocation.location && lat && lng) {
        const userLat = reduxLocation.location.lat
        const userLng = reduxLocation.location.lng

        distanceKilometers = getDistanceKilometers(userLat,userLng,lat,lng)
    }
    const setBadgeVisibleTrue =()=>{
        setBadgeVisible(true)
    }
    const setBadgeVisibleFalse =()=>{
        //console.log('avail',availability);
        setBadgeVisible(false)
    }
    const setDetailsModalTrue =()=>{
        setDetailsModalOpen(true)
    }
    const setDetailsModalFalse =()=>{
        //console.log('avail',availability);
        setDetailsModalOpen(false)
    }
    //const filters = formatFilters(restaurantFilters)

    return (
        <div className={"Availability-Details-Small"}>
            <div className={"Availability-Details-Small-Left"}>
                <div className={"Availability-Details-Small-Header"}>
                <b style={{cursor: 'pointer'}} onClick={setDetailsModalTrue} title='View Restaurant Info'>{!presentName ?title :null}</b>
                    <AvailabilityStartTime time={time}/>
                </div>
                <div className={"Availability-Details-Info"}>
                    <AvailabilityPersons chairs={chairs}/>
                    <Distance distance={distanceKilometers} />
                </div>
                { 
                    availability.badge && availability.badge.length > 0 ?
                    <div className={"Availability-Details-Info"}>
                        <img src={gbox} alt="badge" onClick={setBadgeVisibleTrue}  className="Availability-Badge" title="Click to check your offer!"/> 
                    </div> :
                    null
                }
            </div>
            <div className={"Availability-Details-Small-Right"}>
                <AvailabilityDate time={time} />
                <BookButton onClick={() => openConfirmModal(availabilityId, availability)} text={"Book"} />
            </div>
            <RestaurantDetailsPopup  isOpen={detailsModalIsOpen} availability={availability} onRequestClose={()=> setDetailsModalFalse()}/>
            <BadgePopup  viewBadge={badgeIsVisible} data={availability} hideBadge={()=> setBadgeVisibleFalse()}/>
        </div>
        
    )
}
export default AvailabilityDetailsSmall