import { collectionHandler } from "./collections"
import { query, where, getDocs, orderBy, doc, updateDoc } from "firebase/firestore";
import { enumeration } from '../constants/enumeration';
import { quickReservationCancelMinutes } from "../app/settings";

const {
    CONFIRMED,
    NEITHER,
    CUSTOMERCANCEL,
    RESTAURANTBEACON,
    RESTAURANT,
    PENDING
} = enumeration;

export const upcomingCustomerReservations = async (customerID: string) => {

    const currentTime = new Date()

    try {
        const reservationQuery = query(
            collectionHandler('primaryInteractions'),
            where('interactionType', '==', 'restaurantBeacon'),
            where('customerID', '==', customerID),
            //where('status', '==', CONFIRMED),
            where('time', '>=', currentTime),
            orderBy('time')
        )
    
        const reservationSnapshot = await getDocs(reservationQuery)
    
        return reservationSnapshot.docs
    }
    catch (error) {
        console.log('fetching upcoming reservations failed:');
        console.log(error)
    }
}

export const customerReservationHistory = async (customerID: string) => {
    const currentTime = new Date()
    try {   
        const reservationQuery = query(
            collectionHandler('primaryInteractions'),
            where('interactionType', '==', 'restaurantBeacon'),
            where('customerID', '==', customerID),
            where('status', 'in', ['confirmed','confirmedNoShow','customerCancel', 'restaurantCancel',
            'confirmedHistory']),
            where('time', '<', currentTime),
            orderBy('time', 'desc')
        )

        const reservationSnapshot = await getDocs(reservationQuery)
        console.log(reservationSnapshot.docs)
        return reservationSnapshot.docs
    }
    catch (error) {
        console.log('reshistory');
        console.log(error)
    }
}
export const allCustomerReservations = async (customerID: string) => {
    //const currentTime = new Date()

    try {   
        const reservationQuery = query(
            collectionHandler('primaryInteractions'),
            where('interactionType', '==', 'restaurantBeacon'),
            where('customerID', '==', customerID),
            where('status', 'in', ['confirmed','confirmedNoShow','customerCancel', 'restaurantCancel',
            'confirmedHistory']),
        //  where('time', '<', currentTime),
            orderBy('time', 'desc')
        )

        const reservationSnapshot = await getDocs(reservationQuery)

        console.log(reservationSnapshot.docs)
        return reservationSnapshot.docs
    }
    catch (error) {
        console.log('allcustomerreservationserror');
        console.log(error)
    }
}
export const cancelReservation = async (primaryInteractionKey : string, primaryInteraction : any) => {
    const { interactionType, status, bookingTime, originalChairs, chairs } = primaryInteraction

    const updateData = {
        animatesFor: RESTAURANT,
        status: CUSTOMERCANCEL,
        customerID: null,
        customerName: null,
        customerRating: null,
        customerFilters: null,
        bookingTime: null,
        chairs: chairs
    }

    if (interactionType === RESTAURANTBEACON && status === CONFIRMED) {

        // If cancelled right away (in 10 minutes), return to availabilities immediately.
        if (bookingTime && bookingTime.toDate().getTime() > (new Date().getTime() - 60000 * quickReservationCancelMinutes)) {
            updateData.animatesFor = NEITHER
            updateData.status = PENDING
            updateData.chairs = originalChairs
        }

        const reservationRef = doc(collectionHandler('primaryInteractions'),primaryInteractionKey)

        await updateDoc(reservationRef, {
            ...updateData
        });

        alert("Reservation cancelled")
        return true
    }
    else {
        alert("Invalid reservation")
        return false
    }
}