import { Container } from "react-bootstrap"
import RestaurantDetailsBody from "./RestaurantDetailsBody"
import RestaurantDetailsHeader from "./RestaurantDetailsHeader"
import RestaurantFilters from "./RestaurantFilters"

const RestaurantDetails = (props) => {
    const { restaurant, placeID, className } = props

    return (
        <div className={className}>
          <Container>  
            <RestaurantDetailsHeader restaurantName={restaurant.restaurantName} />
            <RestaurantFilters filters={restaurant.filters}/>
            <RestaurantDetailsBody restaurant={restaurant} placeID={placeID} isActive={true} />
          </Container>  
        </div>
    )
}
export default RestaurantDetails