const RestaurantFilters = (props) => {
    const { filters } = props

    if (!filters || !filters[0]) {
        return null
    }

    return (
        <span>{(filters[0]) +
            ( filters[1] ?", " +filters[1] :"") +
            ( filters[2] ?", " +filters[2] :"") +
            ( filters[3] ?", " +filters[3] :"") 
            }</span>
    )
}
export default RestaurantFilters