import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import { signup } from '../../actions/user'
import { login } from '../../reducers/userSlice'
import { useAppDispatch } from '../../app/hooks';

import "../../styles/Buttons.css";

const EmailRegistrationView = (props) => {

    const { close } = props

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [verifyPassword, setVerifyPassword] = useState('')

    const handleRegistration = async (event, email: string, password: string, verifyPassword: string) => {
        event.preventDefault();

        if (password !== verifyPassword) {
            alert("Passwords do not match");
            return
        }

        if (await signup(true, email, password)) {
            close()
        }
    }

    return (
        <form onSubmit={(event) => {handleRegistration(event, email, password, verifyPassword)}}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" onChange={(event) => setEmail(event.target.value)} />
            </Form.Group>
            
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" onChange={(event) => setPassword(event.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicVerifyPassword">
                <Form.Label>Verify Password</Form.Label>
                <Form.Control type="password" placeholder="Verify Password" onChange={(event) => setVerifyPassword(event.target.value)} />
            </Form.Group>
            <button className='neutralButton' type="submit">
                Register
            </button>
        </form>
    )
}
export default EmailRegistrationView