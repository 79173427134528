import Modal from 'react-bootstrap/Modal'
const BadgePopup = (props) => {

  const { data, viewBadge, hideBadge } = props
    //console.log(data, viewBadge, hideBadge);
  if(!data || !viewBadge) {
      return null;
  }
  return (
    <Modal show={viewBadge} onHide={hideBadge} className="Availability-confirm-popup" backdrop="static">
    <Modal.Header closeButton>
     <h1> Offers in This Availabiltiy </h1>
    </Modal.Header>

    <Modal.Body>
   <p className='cursive'> Book this availability and redeem these from the restaurant </p>
    {
        data.badge.map(function(badge, i){
            //console.log('test');
            return <div> <h3>{(i+1).toString()+'. '}
             {badge.badgeName}</h3><p>  {'\n\n'}
            {badge.badgeContent}</p></div>
          })
    }
    </Modal.Body>
</Modal>
  )
}

export default BadgePopup