import React from 'react'
import { toHours } from '../../helpers/timeHandler' 

require('../../styles/Reservations.css')

const ReservationListItem = (props) => {
    const { reservation, onClick, date } = props
    const {restaurantName, chairs, time, status} = reservation
    
    const personIcon = require('../../assets/images/person_icon.png')

    const hours = toHours(time)

    const statusStr = status.replace(/([A-Z])/g, ' $1')
    //console.log(statusStr);
    const  renderSwitch =(statusStr) => {
        switch(statusStr) {
          case 'confirmed History':
            return 'History';
          case 'customer Cancel':
            return 'Cancelled';
          case 'restaurant Cancel':
            return 'Cancelled by restaurant';
          case 'confirmed':
            return 'Coming Up';
          default:
            return '??';
        }
      }
    return (
      <>
        {date ? date : null}
        <div className={`Reservation-List-Item ${status}`} onClick={onClick}>
            <div className="Reservation-List-Item-Status">
                { renderSwitch(statusStr) }
            </div>
            <div className="Reservation-List-Item-Restaurant">
                {restaurantName}
            </div>
            <div className="Reservation-List-Item-Info">
                <div>
                    <img src={personIcon} className="Person-icon" alt="logo" /> 
                    {chairs}
                </div>
                <div>
                    {hours}
                </div>
            </div>
        </div>
      </>
    )
}

export default ReservationListItem