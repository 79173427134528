import RestaurantAvailability from "./RestaurantAvailability"
import { useState } from 'react'
import AvailabilityConfirmPopup from './AvailabilityConfirmPopup'
//import { formatFilters } from '../../helpers/availabilities';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { showLoginPopup } from '../../reducers/loginPopupSlice';

import "../../styles/AvailabilityDetailsSmall.css"
import "../../styles/Buttons.css"

const RestaurantAvailabilityList = (props) => {
    const { availabilities, noName }  = props

    const reduxUser = useAppSelector((state) => state.user)
    const dispatch = useAppDispatch()

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [selectedAvailability, setSelectedAvailability] = useState({id: null})

    const openConfirmModal = (availabilityId, availability) => {
        if (reduxUser.user) {
            availability.id = availabilityId
            setSelectedAvailability(availability)
            setShowConfirmModal(true)
        }
        else {
            dispatch(showLoginPopup())
        }
    }
    const closeConfirmModal = () => setShowConfirmModal(false)

    return (
        <div className="Restaurant-Availabilities">
            {availabilities ? availabilities.map((availability) => {
                return <RestaurantAvailability key={availability.id} availabilityId={availability.id} availability={availability.data()} isListItem={false} presentName={noName} openConfirmModal={openConfirmModal} />
            }) : null}

            <AvailabilityConfirmPopup availabilityId={selectedAvailability.id} availability={selectedAvailability} show={showConfirmModal} onHide={closeConfirmModal} />
        </div>
    )
}

export default RestaurantAvailabilityList