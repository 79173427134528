import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { toDateStr } from "../../helpers/timeHandler";


const AvailabilityDate = (props) => {
    const { time } = props

    const dateStr = toDateStr(time)

    return (
        <div className={"Availability-Date"}>
            <CalendarTodayIcon /> {dateStr}
        </div>
    )
}
export default AvailabilityDate