import AvailabilityListItem from './AvailabilityListItem';
import { useState } from 'react'
import AvailabilityConfirmPopup from './AvailabilityConfirmPopup'
//import { formatFilters } from '../../helpers/availabilities';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { showLoginPopup } from '../../reducers/loginPopupSlice';

const AvailabilityList = (props) => {
    const { availabilities, isSelected, onClick, selectedAvailability, className }  = props

    const reduxUser = useAppSelector((state) => state.user)
    const dispatch = useAppDispatch()

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [confirmAvailability, setConfirmAvailability] = useState({id: null})

    const openConfirmModal = (availabilityId, availability) => {
        if (reduxUser.user) {
            availability.id = availabilityId
            setConfirmAvailability(availability)
            setShowConfirmModal(true)
        }
        else {
            dispatch(showLoginPopup())
        }
    }
    const closeConfirmModal = () => setShowConfirmModal(false)

    return (
        <div className={`Availability-List ${className ? className : ""}`}>
            {
                availabilities && availabilities.length > 0 ? 
                availabilities.map((availability) => {
                    return <AvailabilityListItem key={availability.id} availabilityId={availability.id} availability={availability.data()} isSelected={isSelected ? isSelected(availability, selectedAvailability) : null} onClick={onClick ? () => onClick(availability) : null} openConfirmModal={openConfirmModal}/>
                }) : 
                <p style={{textAlign: 'center'}}>No availabilities right now!</p>
            }

            <AvailabilityConfirmPopup availabilityId={confirmAvailability.id} availability={confirmAvailability} show={showConfirmModal} onHide={closeConfirmModal} />
        </div>
    )
}

export default AvailabilityList