import { getGeohashRange } from "../helpers/geoHashing";
import { collectionHandler } from "./collections"
import { query, where, getDocs, getDoc, doc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const queryCaller = httpsCallable(functions, 'queryCaller');
const photoCaller = httpsCallable(functions, 'photoCaller');


/**
 * Firebase Database call functions for restaurant users.
 */

const restaurants = {
    
    getRestaurants: async function (lat : number, lng : number) {

        const range = getGeohashRange(lat,lng)
        
        const restQuery = query(collectionHandler('mapRestaurants'), where('geoHash', 'in', range))

        const restSnapshot = await getDocs(restQuery)

        return restSnapshot.docs.map(doc => doc.data())

    },

    getRestaurant: async function (placeID: string) {

        const restRef = doc(collectionHandler('mapRestaurants'), placeID)
    
        const restSnap = await getDoc(restRef)
        
        return restSnap.data()
    },

    async getPlaceDetails(placeID: string): Promise<any>{

            const detailurl = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeID}`; 
            let res ={};
          const res2 =   await queryCaller({ url: detailurl })
                .then( async (result) => {
                    res = result;
                    return res;
                })
            .catch((error) => {
                // Getting the Error details.
                /*
                const code = error.code;
                const message = error.message;
                const details = error.details;
                */
                });
             return res2;

       
    },
    async getPhotos(photoRef: string): Promise<any>{

        const photourl = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=700&photoreference=${photoRef}`;
        let res ={};
      const res2 =   await photoCaller({ url: photourl })
            .then( async (result) => {
                res = result;
                return res;
            })
        .catch((error) => {
            // Getting the Error details.
            /*
            const code = error.code;
            const message = error.message;
            const details = error.details;
            */
            });
         return res2;

   
}
}

export default restaurants;
