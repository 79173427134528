import Review from './Review'

const Reviews = (props) => {
    const { reviews } = props

    if (!reviews || reviews.length === 0) {
        return null
    }
    return (
        <div>
            <h5>Reviews</h5>
            <br></br>
            {reviews.map((review, i) => {
                return (
                    <Review key={i} review={review}/>
                )
            })}
        </div>
    )
}
export default Reviews