export const enumeration = {
    RESTAURANT: 'restaurant',
    CUSTOMER: 'customer',
    NEITHER: 'neither',
    BOTH: 'both',
    
    PENDING: 'pending',
    
    EXPIRED: 'expired',
    REMOVED: 'removed',
    REFUSED: 'refused',
    DECLINED: 'declined',
    DECLINEHANDLED: 'declineHandled',
    HISTORY: 'history',
    
    CONFIRMED: 'confirmed',
    CONFIRMEDNOSHOW: 'confirmedNoShow',
    CONFIRMEDHISTORY: 'confirmedHistory',
    
    RESTAURANTCANCEL: 'restaurantCancel',
    RESTAURANTCANCELHANDLED: 'restaurantCancelHandled',
    
    CUSTOMERCANCEL: 'customerCancel',
    CUSTOMERCANCELHANDLED: 'customerCancelHandled',
    
    INITIAL: 'initial',
    
    CUSTOMERRESERVATION: 'customerReservation',
    CUSTOMERBEACON: 'customerBeacon',
    RESTAURANTBEACON: 'restaurantBeacon',
    
    BEACONOFFER: 'beaconOffer',
    ACCEPTCHECK: 'acceptCheck',
    ROWCLICK: 'rowClick',
    CANCELCHECK: 'cancelCheck',
    TIMESUGGESTION: 'timeSuggestion'
};