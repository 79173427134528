import Modal from 'react-bootstrap/Modal';
//import useWindowDimensions from '../../constants/windowDimensions';
import {Container} from 'react-bootstrap';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import RestaurantDetailsHeader from './RestaurantDetailsHeader';
import RestaurantFilters from './RestaurantFilters';
import RestaurantDetailsBody from './RestaurantDetailsBody';
import { useState, useEffect } from 'react';
import restaurants from '../../db/restaurants';
//import { getRestaurantAvailabilitiesbyPlaceIDQuery } from '../../db/availabilities'
//import { onSnapshot } from "firebase/firestore"
//import AvailabilityList from '../availabilities/AvailabilityList';

require('../../styles/RestaurantDetails.css')

const RestaurantDetailsPopup = (props) => {
    const { isOpen, data, restaurant, onRequestClose, availability } = props
    //const { height, width } = useWindowDimensions();
    const [availabilityRestaur, setAvailabilityRestaurant] = useState({});
   // console.log(props);
    
    useEffect(() => {
      // Update the document title using the browser API
      if(isOpen && availability){
          restaurants.getRestaurant(availability.placeID).then((result) => {
            //console.log('availabilityhere')
            //console.log(availabilityRestaurant);
            setAvailabilityRestaurant(result);
        }).catch((error) => {
          //console.log(error);
        });
      
    }
    },[isOpen, availability]);
 
    const item = restaurant ? restaurant : data ? data.item : availabilityRestaur? availabilityRestaur :null;
   //  console.log('availabilityrestaur' +JSON.stringify(availabilityRestaur));
   //  alert(JSON.stringify(availabilityRestaur));
   // console.log ('avail' + availabilityRestaurant)
  //  console.log('item' +JSON.stringify(item));
        
    if (!item || !item.restaurantName) {
          return null
          }
    

    return (
        <Modal size='lg' show={isOpen} onHide={onRequestClose} className="ResDetails-popup">
             <Modal.Header  closeButton>
              <RestaurantDetailsHeader restaurantName={item.restaurantName ? item.restaurantName : ''}/>
            </Modal.Header>
             { 
              item.filters[0] ? 
              <Modal.Header>
                <h3><RestaurantFilters filters={item.filters}/></h3>
              </Modal.Header> :
              null 
            }
            <Modal.Body>
              <Container>  
                 <RestaurantDetailsBody restaurant={item} placeID={item.place_id} isActive={isOpen} /> 
               </Container>
            </Modal.Body> 
        </Modal>
    )
}

export default RestaurantDetailsPopup;