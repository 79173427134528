
import { collectionHandler } from '../db/collections'
import { auth, googleProvider, appleProvider } from '../firebase'
import { signInWithEmailAndPassword, signInWithPopup, reauthenticateWithCredential, updatePassword, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { doc, updateDoc, getDoc, setDoc } from 'firebase/firestore'
import { logout } from '../reducers/userSlice'
import { getUser } from '../db/users'
import { EmailAuthProvider } from "firebase/auth";
import ReactGA from 'react-ga';
//const isCustomer = true

/**
 * Retrieves the given email and password from redux store, and tries to log in to firebase.
 * 
 * @param isCustomer Determines if the user is a customer or not (restaurant).
 */
export const emailLogin = async (isCustomer: boolean, email: string, password: string) => {
    const currentDate = new Date()

    let returnedUser = null

    try{      
        await signInWithEmailAndPassword(auth, email, password)
        .then(async (response)=> {
            if (!response.user.emailVerified) {
                alert("Email hasn't been verified!");
                throw new Error();   
            }
            
            const queriedCollection = isCustomer ? 'customers' : 'restaurants';

            const tempUser = await getUser(email, isCustomer ? 'customers' : 'restaurants')

            if (!tempUser) {

                const user = {
                    uid: response.user.uid,
                    creationDate: currentDate,
                    email: email.toLowerCase(),
                    notify: 'yes',
                    providerId: 'email',
                }

                const userRef = doc(collectionHandler(queriedCollection), response.user.email.toLowerCase())
                await updateDoc(userRef, user)

                if (isCustomer) {
                    const statistic = {
                        cancelCount: 0,
                        customerRating: 10000,
                        noShowCount: 0,
                        ratingCombo: 3,
                        reservationsConfirmed: 0,
                        reservationsTotal: 0
                    }

                    const statisticsRef = doc(collectionHandler('customerStatistics'), response.user.uid)
                    await updateDoc(statisticsRef, statistic)
                }

                returnedUser = user
            }
            else {
                returnedUser = tempUser
            }

            //setLoginType(response.user.uid, isCustomer);
            //dispatch(getUser(response.user.email.toLowerCase(), isCustomer));
            /*analytics().logLogin({
                method: 'email',
                });
            */
            
        })
        .catch((error)=>{
        })
    }catch(error){
    }
    ReactGA.event({
        category:'LOG_IN',
        action: 'COMPLETE',
        label:'EMAIL_LOGIN',
        nonInteraction:false

    })
    return returnedUser
}

export const googleLogin = async () => {
    let returnedUser = null

    await signInWithPopup(auth, googleProvider)
        .then((result) => {
            returnedUser = result.user;
        }).catch((error) => {
            // Handle Errors here.
            /*
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            */
        }
    );
    ReactGA.event({
        category:'LOG_IN',
        action: 'COMPLETE',
        label:'GOOGLE_LOGIN',
        nonInteraction:false

    })
    return returnedUser
}

export const appleLogin = async () => {
    let returnedUser = null

    await signInWithPopup(auth, appleProvider)
        .then((result) => {
            returnedUser = result.user;
        }).catch((error) => {
            // Handle Errors here.
            /*
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The AuthCredential type that was used.
            const credential = OAuthProvider.credentialFromError(error);

            console.log(errorCode)
            console.log(errorMessage)
            console.log(email)
            console.log(credential)
            */
        }
    );
    ReactGA.event({
        category:'LOG_IN',
        action: 'COMPLETE',
        label:'APPLE_LOGIN',
        nonInteraction:false

    })
    return returnedUser
}

export const signout = (dispatch) => {
    if (auth.currentUser !== null) {
        auth.signOut();
    }
    dispatch(logout())
}

export const signup = async (isCustomer: boolean, email: string, password: string) => {
    const currentDate = new Date()
    try {
        const response = await createUserWithEmailAndPassword(auth, email, password)

        if (response.user.email) {
            const uid = response.user.uid

            const user = {
                uid: uid,
                creationDate: currentDate,
                email: email.toLowerCase(),
                notify: 'yes',
                providerId: 'email'
            }

            email = email.toLowerCase()

            const queriedCollection = isCustomer ? 'customers' : 'restaurants';

            /**
             * Tries to retrieve user from Firestore.
             */
            const userRef = doc(collectionHandler(queriedCollection), email)
            const docSnap = await getDoc(userRef)
            const tempUser = docSnap.data()

            /**
             * If no user is found, create a new entry in Firestore.
             */
            if (!tempUser) {
                await setDoc(doc(collectionHandler(queriedCollection), email), user)

                if (isCustomer) {
                    const statistic = {
                        cancelCount: 0,
                        customerRating: 100,
                        noShowCount: 0,
                        ratingCombo: 3,
                        reservationsConfirmed: 0,
                        reservationsTotal: 0
                    }

                    await setDoc(doc(collectionHandler('customerStatistics'), uid), statistic)
                }
            }

            await sendEmailVerification(response.user)

            let alertMessage = 'Registration successful!';
            alert(alertMessage);

            return true
        }
        return false
    }
    catch(error){
        let alertMessage = 'Error signing up! Please try again later.';
        switch(error.code) {
            case 'auth/weak-password':
                alertMessage = error.message
                alertMessage = alertMessage.replace('Firebase: ', 'Error: ')
                alertMessage = alertMessage.replace(' (auth/weak-password)', '')
                break;
        }
        alert(alertMessage);
        return false;
    }
}

export const changePassword = async (currentPassword, newPassword, newPasswordCheck) => {
    const user = auth.currentUser
    let returnValue = false
    if (user) {
        if (newPassword === newPasswordCheck) {
            try {
                var credential = EmailAuthProvider.credential(user.email, currentPassword);
                await reauthenticateWithCredential(user, credential)
                await updatePassword(user, newPassword)
                alert("Password Changed")
                returnValue = true
            }
            catch (error) {
                switch(error.code) {
                    case 'auth/wrong-password':
                          alert('Wrong Password')
                          break;
                    case 'auth/weak-password':
                        alert('Weak Password')
                        break;
                    default:
                        alert('Password change failed')
                }
            }
        } else {
            alert("passwordsDoNotMatch");
        }
    }
    else {
        alert("Not logged in!")
    }
    return returnValue
}