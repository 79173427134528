import { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import Profile from './Profile';
import ChangePassword from './ChangePassword';
import ArrowBack from '@mui/icons-material/ArrowBack'

const ProfileMenu = (props) => {
    const { onHide, show } = props

    const [selectedMenu, setSelectedMenu] = useState("Profile")

    const closeWindow = () => {
        onHide()
        setSelectedMenu("Profile")
    }

    let shownMenu = <Profile onHide={onHide} selectMenu={setSelectedMenu}/>
  
    if (selectedMenu === "ChangePassword") {
      shownMenu = <ChangePassword onHide={onHide} selectMenu={setSelectedMenu}/>
    }

    let backButton = <ArrowBack style={{cursor: "pointer"}} onClick={() => setSelectedMenu("Profile")}/>
    if (selectedMenu === "Profile") {
        backButton = null
    }

    return (
        <Modal show={show} onHide={closeWindow}>
            <Modal.Header closeButton>
                <h1>Profile</h1>
            </Modal.Header>
            <Modal.Body>
                {backButton}
                {shownMenu}
            </Modal.Body>
        </Modal>
    )
}

export default ProfileMenu