import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getDistanceStr } from "../../helpers/location";

const Distance = (props) => {
    const { distance } = props

    if (!distance) {
      return null
    }

    const distanceStr = <span>{getDistanceStr(distance)}</span>

    return (
        <div>
          <LocationOnIcon />
          {distanceStr}
        </div>
    )
}
export default Distance