import { collection, doc } from "firebase/firestore";
import { db } from "../firebase";
import { testMode } from '../app/settings'

export const collectionHandler = function(collectionString) {
    if (testMode) {        
        return collection(doc(collection(db, 'testCollection'), 'testCollection'), collectionString)
    }
    else {
        return collection(db, collectionString)
    }
}