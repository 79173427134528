import { useState, useEffect } from 'react';
import BootstrapCarousel from "../BootstrapCarousel"
import restaurants from '../../db/restaurants'
import { getRestaurantAvailabilitiesbyPlaceIDQuery } from "../../db/availabilities";
import { onSnapshot } from "firebase/firestore";
import RestaurantAvailabilityList from '../availabilities/RestaurantAvailabilityList';
import OpeningHours from './OpeningHours';
import Reviews from './Reviews';

const RestaurantDetailsBody = (props) => {
    const { restaurant, placeID, isActive } = props

    const [imageArr, setIntoImageArr] = useState([]);
    const [fetchDone, setFetchDone] = useState(false)
    const [availabilities, setAvailablities] = useState([]);
    const [placeDetails, setPlaceDetails] = useState(null)

    useEffect(() => {
        let isMounted = true

        // Update the document title using the browser API
        if (isActive && restaurant) {
          restaurants.getPlaceDetails(restaurant.place_id).then((result) => {
            if (isMounted) {
              setPlaceDetails(result)
            }
          })
        }

        return () => {
          isMounted = false
        };
      },[isActive, restaurant]);

      useEffect(() => {
          let isMounted = true

          if (isMounted && placeDetails && placeDetails.data && placeDetails.data.result && placeDetails.data.result.photos) {
            let imgTemp=[];
            const queryLen = placeDetails.data.result.photos.length;
            placeDetails.data.result.photos.forEach((photo) => {
              if (isMounted) {
                restaurants.getPhotos(photo.photo_reference).then((result) => {
                  if (isMounted) {
                      imgTemp.push(result);
                      if (imgTemp && queryLen && imgTemp.length > 0 && imgTemp.length === queryLen) {
                        setIntoImageArr(imgTemp)
                        setFetchDone(true);
                      }
                  }
                });
              }
            })
          }
          
          return () => {
            isMounted = false
          };
      },[placeDetails])

      useEffect(() => {
        const availabilityQuery =
          getRestaurantAvailabilitiesbyPlaceIDQuery(placeID);
    
        const unsubscribe = onSnapshot(availabilityQuery, (querySnapshot) => {
          const availabilities = [];
          querySnapshot.forEach((doc) => {
            availabilities.push(doc);
          });
    
          setAvailablities(availabilities);
        });
    
        return () => {
          unsubscribe();
        };
      }, [placeID]);

    return (
        <div>
            <div className="row" style={{marginLeft:'5%', marginRight:'5%'}}>
                <p>{restaurant.shortDescription}</p>
            </div>
            <div className="row" style={{marginLeft:'5%', marginRight:'5%'}}>          
                <div className="column">{restaurant.longDescription}</div>
            </div> 
            <div className="row">
                {availabilities && availabilities.length > 0 ? (
                    <div>
                        <h3 style={{marginTop:'3%'}}>Reserve a table</h3>

                        <RestaurantAvailabilityList availabilities={availabilities} noName={true} />
                    </div>
                    ) : (
                        <p>No availabilities right now!</p>
                )}
            </div>
     
            <br></br> 
            <div className="row">
              <OpeningHours openingHours={restaurant.openingHours}/>
            </div>  
            <br></br>
            {/* <div className="row" style={{maxHeight:'5%'}}>
                <div className="column" style={{width:'80%'}}>
                    {imageArr && fetchDone ? <BootstrapCarousel imageArr={imageArr}></BootstrapCarousel>
                    : null }
                </div>
            </div>
            <br></br> */}
            { placeDetails && placeDetails.data.result.reviews.length >0 ?
              <div className="row"  style={{marginLeft:'4%', marginRight:'4%'}}>
                <Reviews reviews={placeDetails.data.result.reviews}/>
              </div> 
              :
              null
            }
   
        </div >
    )
}
export default RestaurantDetailsBody