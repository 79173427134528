import '../styles/App.scss';
const PrivacyPolicy = () => {
    return (
    <><div className="privacyPolicy">
            <p>
                This Privacy Policy is valid for users registering on or after 1.10.2020
                <br></br><br></br>
                This Privacy Policy describes the principles related to the collection, transfer, processing and sharing of customers’ personal data by NoMoQu Oy and its subsidiaries (together "We"). The Customer ("You", the "User" or the "Data Subject") is a person who uses our service ("Online Services") or otherwise contacts us.
                <br></br><br></br>
                This Privacy Statement applies to all personal data processed by us in connection with our Online Services, including the personal data of visitors to our website and the personnel of our partners. However, this Privacy Statement only applies to the processing of personal data by us and not the processing of personal data by third parties. To the extent permitted by applicable regulations, we do not assume responsibility for the processing of personal data by third parties.
                <br></br><br></br>
                This Privacy Statement also describes how You as a Data Subject can exercise your rights under the existing legislation. Please contact our privacy representative mentioned in section 1 if you require more information on the subject matter of this Privacy Statement.
            </p>

            <p style={{ fontWeight: 'bold' }}>1. Contact information</p><p>
                NoMoQu Oy (Business ID 3017526-6)
                <br></br>
                Otakaari 5<br></br>
                02150 Espoo
                <br></br>
                jono@jonoapp.com
            </p><p style={{ fontWeight: 'bold' }}>2. Personal data and categories of personal data processed and regular sources of data</p><p>
                We collect your personal data and process it automatically.
                <br></br><br></br>
                We may collect your personal data when you sign up for and use our Online Services or when you contact us. The data we collect includes (i) the data that you provide us with or that we obtain directly from you, (ii) the data that we collect automatically, and (iii) the data that we obtain from other sources.
                <br></br><br></br>
                The types of personal data we may collect from you include:
                <br></br>
                - basic information related to you as a person, such as data that we collect when you register with our Online Services, set up and use your own or your partner’s user account, contact us or participate in competitions arranged by us. Information related to you as a person includes items such as your name, telephone number, email address, street address, date of birth, social media profile information and other such information;<br></br>
                - access and transaction data, such as your user ID and password for signing in to our Online Services and data on purchase transactions completed through us;<br></br>
                - profile information, language selection, interests, information on products purchased and sold through our Online Services as well as their prices, payment methods used and other payment information and accounting records for the payment account;<br></br>
                - contractual information, such as transactions and communications related to the service and its use, as well as billing and recovery information; and<br></br>
                - other personal information you provide us, such as direct marketing permissions and bans.
                <br></br>
                When you use our Online Services, we may collect, through the use of automated technology (including service logs, cookies and other similar technologies), the following types of information:
                <br></br>
                - access and transaction data (see above), including log data, and contractual information (see above), some of which is automatically collected by our Online Services;<br></br>
                - data related to the use of the Internet and our online services (e.g. activity on our website, the web page or application you used to access our Online Services, and the duration of your visit)<br></br>
                - data on your computer or mobile device (e.g. IP address, type, operating system and browser as well as their settings and browser version, serial number of the device, the device and/or cookie ID of your mobile device, advertising ID and display resolution); and<br></br>
                - if you have consented to this, we may also collect information about the location of your mobile device or computer.
                <br></br><br></br>
                For information on the use of cookies, see section 8 of the Privacy Statement.
                <br></br><br></br>
                We may also combine data provided by you, collected automatically and retrieved from other sources. In addition, we may collect aggregated data from other sources, such as information on social media activity related to us. An individual user cannot be recognised in such aggregated data.
            </p><p style={{ fontWeight: 'bold' }}>3. Purpose and legal basis of processing personal data</p><p>
                We process your personal data for the following purposes:
                <br></br><br></br>
                - Implementation of the online service: We use personal data for user identification and login; provision, maintenance and protection of online services; billing and recovery; forwarding orders to our partner restaurants; and communication with users. The aforementioned data is processed to comply with the user agreement on Online Services. We cannot provide our service to you if you do not provide your email address and name. In addition, service use requires us to send you communications related to the use of the service.<br></br>
                - Marketing: We use the data for marketing our products and services as well as the products and services of our partners. In this case, the processing is based on the consent of the Data Subject or a legitimate interest. Data Subjects may object to marketing or cancel their consent at any time.<br></br>
                - Product development: We use the data to make our services even better than before. For example, feedback from users and market surveys help us understand what features users would like to see in our Online Services. Such processing is based on our legitimate interest.<br></br>
                - Preventing and investigating misuse: We may need to use the data to prevent and investigate cases of misuse. For example, we may use log data collected automatically to monitor and investigate the activities of the users of our Online Services as well as to check whether their use is authorized. Such processing is based on our legitimate interest.<br></br>
                - Legal obligations: To comply with the Accounting Act, we retain correspondence on payment records and transactions for six years from the end of the calendar year during which our financial year ends. In the above situation, for example, the processing of personal data is based on compliance with a legal obligation.<br></br>
                - Other purposes of the processing, if any, will be communicated to you when we collect data from you or request your consent to the processing of your data.
            </p><p style={{ fontWeight: 'bold' }}>4. Sharing personal data; recipients and groups of recipients</p><p>
                We will only disclose your personal information as described in this Privacy Statement. We will not sell your personal information. However, we may disclose your personal information as follows:
                <br></br><br></br>
                - Partners: When you submit an reservation, we supply our partner restaurant with information necessary, such as your contact information (as a rule, only your name, email address and telephone number) and the details of your reservation. The partner in question will also process your personal data.<br></br>
                - Corporate restructuring: In the event of any sale, transfer or other corporate restructuring, we may disclose data we have collected and retained to the parties involved in the restructuring and their advisors.<br></br>
                - Authorities: We may disclose your personal data as requested by a competent authority to comply with applicable legislation.<br></br>
                - Legal claims, protection of our Online Services and recovery: We may process and disclose your personal data to a third party if it is necessary to protect the technology of our Online Services, to protect and defend the rights or interests of us, our employees or third parties, or to investigate criminal offenses. In addition, we may disclose your personal data to a third party if it is necessary in order to enforce the agreement, to recover receivables, to investigate any breaches or to establish, exercise or defend a legal claim.<br></br>
                - Consent: In general, we may disclose the personal data of a Data Subject to third parties if the data subject has consented to it.
                <br></br><br></br>
                We may use subcontractors in the provision of our Online Services. Data may be transferred to our service providers that perform e.g. data processing services, analytics services or customer service improvement services on our behalf. In this case, we will make appropriate contractual arrangements to ensure that the data is processed in accordance with current legislation and this Privacy Statement.
                <br></br><br></br>
                We may also disclose anonymised information on the use of our Online Services that does not allow individuals to be identified. We may also combine your data with the data of other customers so that you cannot be identified.
            </p><p style={{ fontWeight: 'bold' }}>5. Cross-border transfers of personal data</p><p>
                We and our Group companies pursue business operations and maintain data systems in different countries. We may disclose your information to other entities within our Group. We may transfer or disclose your information to countries where we have business or restaurant partners or where services are performed for us, and also elsewhere if required by corporate restructuring, including countries outside the European Union or the European Economic Area. Data transfers and disclosures are carried out within the framework of applicable legislation. If the European Union does not consider the level of protection in the country in question adequate, we will use contractual practices, the European Commission’s model clauses where applicable and/or other practices to provide adequate security for your personal data. The transfer and disclosure of data will only take place in accordance with this Privacy Statement and for the purposes described in it.
            </p>
                <p style={{ fontWeight: 'bold' }}>6. File protection policy</p><p>
                <br></br><br></br>
                We have appropriately protected data using both administrative and physical means as well as technical solutions (e.g. firewalls and passwords). Our employees are bound to confidentiality.
                <br></br><br></br>
                Despite the protection measures applied, the processing and transfer of data via the Internet always involves risks. We will notify you without undue delay if, despite our measures, a security violation occurs that is likely to result in a high risk to your rights or freedoms.
            </p><p style={{ fontWeight: 'bold' }}>7. Retention period of personal data</p><p>
                We comply with our statutory obligations regarding retention of data.
                <br></br><br></br>
                We will retain your information in accordance with current legislation for as long as necessary for the purposes defined above. After that time, we will remove unnecessary data and continue to process the Data Subject’s name and contact details and individual data items describing the Data Subject’s profile for marketing purposes as long as the data subject does not deny us the right to send marketing messages. If the Data Subject objects to marketing messages and there is no other basis for processing, we will retain the Data Subject’s opt-out decision and contact details to ensure that we are able to enforce that decision. In the above situation, the Data Subject may request the removal of data in its entirety. You may, within the framework of applicable regulations, influence the retention period of data by exercising your rights depicted in this Privacy Statement (see section 9).
                <br></br><br></br>
                We aim to ensure that the personal data in our possession is up to date and valid by updating outdated data and removing unnecessary data. From time to time, we may prompt you to check that your data is up to date.
            </p><p style={{ fontWeight: 'bold' }}>8. Cookies</p><p>
                A cookie is a small p file that your web browser stores on your device. Cookies often contain an anonymous, unique identifier, which allows us to identify and count the browsers used to visit our website. Cookies do not move around in the network. They are only placed on the user’s terminal for the website requested by the user. Only the server that sent the cookie can later read and use it. Cookies or other similar techniques used by us do not damage the user’s terminal or files, and cookies cannot be used to run programs or spread malware.
                <br></br><br></br>
                Our Online Services use both erasable session cookies and cookies stored by the browser. Session cookies remain in the browser’s memory only until the browser is closed. Stored cookies remain on the user’s device for a certain period of time (typically for a few months to a few years) or until the user removes them.
                <br></br><br></br>
                Our Online Services use first-party cookies which are created by the website visible in the address bar. In addition, our Online Services use third-party cookies created by entities, such as advertising networks, measurement, analytics and tracking services, and social media services.
                <br></br><br></br>
                Cookies allow us to remember the Data Subject’s login data and selections, to develop our services and business and, for instance, to enable customized marketing and presenting service users with customized offers based on their likely interests, as well as to investigate any cases of misuse. We also use cookies to monitor the number of visitors to our Online Services and to check whether or not emails or newsletters have been opened and whether any action has been taken on them.
                <br></br><br></br>
                When setting up cookies, users are given the option to refuse the cookies through the use of browser settings or to exercise the opportunities referred to in section 9 of the Privacy Statement.
            </p><p style={{ fontWeight: 'bold' }}>9. Your rights and opportunities</p><p>
                You can influence the processing of your data by exercising the following rights:
                <br></br><br></br>
                - Verifying, correcting and deleting data: You are entitled to verify your stored personal data. Upon your request, any incorrect, incomplete or outdated personal data will be corrected, supplemented or deleted. However, the data will not be removed if it is needed in order to comply with statutory obligations or to establish, exercise or defend a legal claim, or when required to execute the agreement between you and us.<br></br>
                - Data portability: If you wish to have your personal data, which you have provided us with and which is automatically processed based on your consent or agreement with us, transferred elsewhere, please contact the address referred to in section 1 above. Likewise, you can have such personal data sent to you in a structured, commonly used and machine-readable format.<br></br>
                - Right to object and right to restrict: You can object to direct marketing at any time. You are also entitled to object to the processing of your personal data for market research or direct marketing, including profiling, at any time. You can also object to other processing based on a legitimate interest on grounds related to your personal situation. In such a situation, for example, the processing is restricted for the duration of evaluating the grounds for objecting to the processing. The processing can also be restricted if, for example, you contest the accuracy of the personal data, in which case the processing is restricted for the duration of determining the validity of the data.<br></br>
                - Withdrawal of consent: You can cancel your consent at any time by contacting the address referred to in section 1 above.<br></br>
                - Right of appeal: You have the right to file a complaint with the authority if you believe that your information has been processed in violation of existing privacy legislation. However, our primary objective is to resolve any disputes and disagreements concerning the processing of personal data directly with users; for this purpose, please contact the address indicated in section 1 above.<br></br>
                - To exercise your rights, you must submit a written request to that effect to our contact person mentioned in section 1 above. In order to process your request, we may ask you for additional information to verify your identity. We may reject requests that are submitted unreasonably often or are otherwise unjustified. We may also impose a fee for satisfying requests submitted unreasonably often.
                <br></br><br></br>
                Please note that to access the service you need to provide your email address and name. Likewise, the use of the service requires that we may send you communications related to the use of the service and that we may disclose information necessary for processing your order to our partner restaurant. If you do not provide information relevant to the service or require it to be removed or the processing to be restricted, we may prevent or discontinue your access to the Online Services.
            </p><p style={{ fontWeight: 'bold' }}>10. Changes to the Privacy Statement</p><p> We may update this Privacy Statement from time to time.</p>
    </div>
    </>
)
}

export default PrivacyPolicy