import MapAvailability from './MapAvailability'
import Map from '../Map'
import { useAppSelector } from '../../app/hooks'

const AvailabilityMenu = (props) => {
  const { selectedAvailability, mapAvailabilityList } = props

  const reduxLocation = useAppSelector((state) => state.location)

  let lat; let lng;

  if (reduxLocation.location) {
    lat = reduxLocation.location.lat
    lng = reduxLocation.location.lng
  }

  return (
    <div className="Main-menu-content">
      <Map userLat={lat} userLng={lng} selectedItem={selectedAvailability ? selectedAvailability.data() : null}>
          {mapAvailabilityList.map((restaurant) => {
                return <MapAvailability 
                        key={`map_${restaurant.restaurantID}`} 
                        count={restaurant.count}
                        lat={restaurant.lat}
                        lng={restaurant.lng}
                    />
            })}
      </Map>
    </div>
  )
}

export default AvailabilityMenu