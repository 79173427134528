const LoginOptionButton = (props) => {
    const { icon, title, onClick } = props

    return (
        <div className={"Login-button"} onClick={onClick}>
            {icon}
            <div>
                {title}
            </div>
        </div>
    )
}

export default LoginOptionButton