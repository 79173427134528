import LoginOptionButton from './LoginOptionButton';
import { AppleOutlined, GoogleOutlined, MailOutlined } from '@ant-design/icons'
import { googleLogin, appleLogin } from '../../actions/user'
import { login } from '../../reducers/userSlice'
import { useAppDispatch } from '../../app/hooks';
import { getUser } from '../../db/users';

const LoginOptionView = (props) => {
    //const clientId = process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID
    const { close, updateView } = props

    const dispatch = useAppDispatch()

    const handleGoogleLogin = async () => {
        const user = await googleLogin()
    
        if (user) {
            const { uid, email, providerId, accessToken, displayName } = user
            const tmpuser = await getUser(email, 'customers')
            const reduxUser = {uid, email, providerId, token: accessToken, name: displayName ? displayName: tmpuser.name, firstName: (tmpuser.firstName ? tmpuser.firstName : null), 
                lastName: (tmpuser.lastName ? tmpuser.lastName : null),phoneNumber: tmpuser.phoneNumber ?tmpuser.phoneNumber : null}

            dispatch(login(reduxUser))

            close()
        }
    }

    const handleAppleLogin = async () => {
        const user = await appleLogin()
    
        if (user) {
            const { uid, email, providerId, accessToken, displayName } = user
            const tmpuser = await getUser(email, 'customers')
            const reduxUser = {uid, email, providerId, token: accessToken,  name: displayName ? displayName: tmpuser.name, firstName: (tmpuser.firstName ? tmpuser.firstName : null), 
                lastName: (tmpuser.lastName ? tmpuser.lastName : null),phoneNumber: tmpuser.phoneNumber ?tmpuser.phoneNumber : null}

            dispatch(login(reduxUser))

            close()
        }
    }

    return (
        <div className={"Login-button-list"}>
            <LoginOptionButton title="Google" icon={<GoogleOutlined/>} onClick={handleGoogleLogin}/>
            <LoginOptionButton title="Apple" icon={<AppleOutlined/>} onClick={handleAppleLogin}/>
            <LoginOptionButton title="Email" icon={<MailOutlined/>} onClick={() => updateView("email")}/>
            {/*<LoginOptionButton title="Ravintola" icon={<UserOutlined/>}/>*/}
        </div>
    )
}

export default LoginOptionView