export const comparisonTime = (minutes : number, hours : number = 0, days : number = 0) => {
    const cTime= new Date()
    cTime.setMilliseconds(
        cTime.getMilliseconds() - days*24*60*60000 - hours*60*60000 - minutes*60000
        );
    return cTime;
}

export const toHours = (time) => {
    if (!time) {
        return null
    }
    if (time.seconds) {
        time = time.toDate()
    }
    return time.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
}

export const toDate = (time) => {
    if (time) {
        return time.toDate().toLocaleDateString()
    }
}

const isToday = (time) => {
    const today = new Date();
    const selectedTime = time.toDate()

    return selectedTime.getDate() === today.getDate() &&
        selectedTime.getMonth() === today.getMonth() &&
        selectedTime.getFullYear() === today.getFullYear()
}

const isTomorrow = (time) => {
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate()+1);
    const selectedTime = time.toDate()

    return selectedTime.getDate() === tomorrow.getDate() &&
        selectedTime.getMonth() === tomorrow.getMonth() &&
        selectedTime.getFullYear() === tomorrow.getFullYear()
}

export const toDateStr = (time) => {
    if (isToday(time)) {
        return "Today"
    }
    else if (isTomorrow(time)) {
        return "Tomorrow"
    }
    return toDate(time)
}