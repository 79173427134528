import { getDistanceKilometers } from "./location";

const checkAvailabilities = (availabilities, placeID: string) => {
  if(availabilities) {
    for (let i = 0; i < availabilities.length; i++) {
      if (availabilities[i].placeID === placeID) {
        return true
      }
    }
  }
  return false
}

export const populateExtraFields = (restaurants, availabilities, userLat, userLng) => {
  restaurants.forEach((restaurant) => {
    restaurant.hasAvailabilities = checkAvailabilities(availabilities, restaurant.place_id)
    restaurant.distance = getDistanceKilometers(userLat,userLng,restaurant.lat,restaurant.lng);
  })
}

export const sortRestaurantList = (restaurants) => {
  restaurants.sort((a,b) => {
    let aDistance = a.distance
    const distancePriority = 100
    if (a.hasAvailabilities) {
      aDistance = aDistance - distancePriority
    }

    let bDistance = b.distance
    if (b.hasAvailabilities) {
      bDistance = bDistance - distancePriority
    }

    return aDistance - bDistance
  })
}