import AvailabilityDetailsSmall from './AvailabilityDetailsSmall';

const RestaurantAvailability = (props) => {
    const { availability, availabilityId, onClick, openConfirmModal, presentName } = props
    return (
        <div className={`Restaurant-Availability`} onClick={onClick}>
            <AvailabilityDetailsSmall availabilityId={availabilityId} availability={availability} title={availability.restaurantName} presentName={presentName}  openConfirmModal={openConfirmModal} />
        </div>
    )
}

export default RestaurantAvailability