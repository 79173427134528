import { useState, useEffect } from 'react'
import { useAppSelector } from '../../app/hooks';
import ReservationListItem from './ReservationListItem'
import { toDateStr } from '../../helpers/timeHandler' 

const ReservationList = (props) => {

    const reduxUser = useAppSelector((state) => state.user)
    const [reservations, setReservations] = useState([])

    useEffect(() => {
        if (reduxUser.user) {
            props.listFunction(reduxUser.user.uid).then((data) => {
                setReservations(data)
            })
        }
    }, [reduxUser.user, props])

    if (reservations && reservations.length > 0) {
        let prevDate = null
        let date = toDateStr(reservations[0].data().time)

        return (
            <div>
                {reservations.map((res) => {
                    date = toDateStr(res.data().time)
                    if (date !== prevDate || !prevDate) {
                        prevDate = date
                        return (
                            <ReservationListItem onClick={() => props.showReservation(res)} key={res.id} date={date} reservation={res.data()}/>
                        )
                    }
                    return <ReservationListItem onClick={() => props.showReservation(res)} key={res.id} reservation={res.data()}/>
                })}
            </div>
        )
    }
    else {
        return null
    }
}

export default ReservationList