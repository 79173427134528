const makeRadian = (degree: number) => {
    return degree * (Math.PI/180);
}

export const getDistanceKilometers = (currentLat: number, currentLon: number, targetLat: number, targetLon: number) => {
    const radius = 6371; // Earth
    const radianLat = makeRadian(currentLat - targetLat);
    const radianLon = makeRadian(currentLon - targetLon);

    const a = (Math.sin(radianLat/2) * Math.sin(radianLat/2)) + Math.cos(makeRadian(currentLat))
    * Math.cos(makeRadian(targetLat)) * (Math.sin(radianLon/2) * Math.sin(radianLon/2));

    const c = 2 * Math.asin(Math.sqrt(a));
    const distance = radius * c;

    return distance; // kilometers

}

export const getDistanceStr = (distanceKilometers) => {
    if (distanceKilometers <= 0.1) {
        return (distanceKilometers* 1000).toFixed(0) + " m"
    }
    return distanceKilometers.toFixed(2) + " km"
}