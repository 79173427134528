import { formatChairs } from '../../helpers/availabilities';
import PersonIcon from '@mui/icons-material/Person';

const AvailabilityPersons = (props) => {
    const { chairs } = props

    const formattedChairs = formatChairs(chairs)

    return (
        <div className={"Availability-Persons"}>
            <PersonIcon/>
            <i>{formattedChairs} persons</i>
        </div>
    )
}
export default AvailabilityPersons