import { ClockCircleOutlined } from '@ant-design/icons'
import { toHours } from "../../helpers/timeHandler";

const AvailabilityStartTime = (props) => {
    const { time } = props

    const starttime = toHours(time)
    return (
        <div className={"Availability-Start-Time"}>
            <ClockCircleOutlined/> {starttime}
        </div>
    )
}
export default AvailabilityStartTime