import { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react';
import UserMarker from './UserMarker'
import { defaultLat, defaultLng, mapDefaultZoom } from '../app/settings'

const apikey = process.env.REACT_APP_GOOGLE_MAPS_APIKEY

const Map = (props) => {
  const { children, userLat, userLng, selectedItem } = props

  const [center, setCenter] = useState({
    lat: userLat ? userLat : defaultLat,
    lng: userLng ? userLng : defaultLng
  })

  useEffect(() => {
    if (selectedItem && selectedItem.lat && selectedItem.lng) {
      setCenter({
        lat: selectedItem.lat,
        lng: selectedItem.lng
      })
    }
    else if (userLat && userLng) {
      setCenter({
        lat: userLat,
        lng: userLng
      })
    }
    else {
      setCenter({
        lat: defaultLat,
        lng: defaultLng
      })
    }
  }, [selectedItem, userLat, userLng])

  return (
    <div id="Map" style={{ height: 'calc(100vh - 165px)', width: 'calc(100vw - 505px)' }}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: apikey }}
            center={center}
            defaultZoom={mapDefaultZoom}
        >
          {userLat && userLng ? <UserMarker lat={userLat} lng={userLng}/> : null}
          {children}
        </GoogleMapReact>
      </div>
  )
}

export default Map