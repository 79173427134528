import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { signout } from '../../actions/user';
import { getUserStatistics } from '../../db/users';
import "../../styles/Buttons.css";

const Profile = (props) => {
    const { onHide, selectMenu } = props

    const reduxUser = useAppSelector((state) => state.user)
    const dispatch = useAppDispatch()
    const [statistics, setStatistics] = useState(null)

    const handleLogout = () => {
        signout(dispatch)
        onHide()
    }

    useEffect(() => {
        if (!statistics && reduxUser.user) {
            getUserStatistics(reduxUser.user.uid).then((result) => {
                setStatistics(result)
            })
        }
    }, [statistics, reduxUser])

  return (
    <div>
        <h4>{reduxUser.user ? reduxUser.user.name : null}</h4>
        <p>My Rating: {statistics ? statistics.customerRating : null}</p>
        <p>Total Reservations: {statistics ? statistics.reservationsTotal : null}</p>
        {
        reduxUser.user && reduxUser.user.providerId === "email" 
        ?
            <>
                <button
                    className="neutralButton"
                    aria-label="logout"
                    onClick={() => selectMenu("ChangePassword")}
                    >
                    Change Password
                </button>
                <br/>
                <br/>
            </>
        :
        null
        }

        <button
            className="cancelButton"
            aria-label="logout"
            onClick={() => handleLogout()}
            >
            Log Out
        </button>
    </div>
  )
}

export default Profile