
import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { getRestaurantAvailabilityQuery } from "../db/availabilities"
import { useAppSelector } from '../app/hooks'
import { onSnapshot } from "firebase/firestore";
import AvailabilityMenu from './availabilities/AvailabilityMenu'
import MainMenuOptionButton from './MainMenuOptionButton'
import RestaurantMenu from './restaurants/RestaurantMenu'
import '../styles/MainMenu.css';
import AvailabilityList from './availabilities/AvailabilityList';
import { toHours } from '../helpers/timeHandler';
import restaurants from "../db/restaurants"
import RestaurantDetails from './restaurants/RestaurantDetails';
import { defaultLat, defaultLng } from '../app/settings';
import ReactGA from 'react-ga';
require('../styles/LandingPage.css')
const yonoLogo = require('../assets/images/YONO_logo.png')

const MainMenu = (props) => {
    const { restaurantNameFilter, searchFilters, landingPage, isSmallScreen } = props
    let placeID = props.placeID
    const { persons, from, to, tags } = searchFilters
    const params = useParams();

    const [selectedMenu, setSelectedMenu] = useState(null)
    const [selectedRestaurant, setSelectedRestaurant] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const [availabilityList, setAvailabilityList] = useState([]);
    const [filteredAvailabilityList, setFilteredAvailabilityList] = useState([]);
    const [mapAvailabilityList, setMapAvailabilityList] = useState([]);
    const [selectedAvailability, setSelectedAvailability] = useState(null)

    let shownMenu = null

    if (landingPage && !placeID) {
        placeID = params.placeID
    }

    let availabilityMenu = <AvailabilityMenu selectedAvailability={selectedAvailability} mapAvailabilityList={mapAvailabilityList}/>

    if (isSmallScreen) {
        availabilityMenu = <AvailabilityList className="Main-menu-content" availabilities={filteredAvailabilityList}/>
    }

    const restaurantMenu = <RestaurantMenu restaurantNameFilter={restaurantNameFilter} availabilities={filteredAvailabilityList}/>
    const landingPageMenu = <RestaurantDetails restaurant={selectedRestaurant} placeID={placeID} className="landingPage Main-menu-content"/>

    const reduxLocation = useAppSelector((state) => state.location)

    let lat; let lng;

    if (reduxLocation.location) {
        lat = reduxLocation.location.lat
        lng = reduxLocation.location.lng
    }
    else {
        lat = defaultLat
        lng = defaultLng
    }

    useEffect(() => {
        if (placeID && !selectedRestaurant) {
            restaurants.getRestaurant(placeID).then((data) => {
                if (data) {
                    setSelectedRestaurant(data)
                    setSelectedMenu("LandingPage")
                }
                else {
                    setSelectedMenu("Availabilities")
                }
                setIsLoading(false)
            })
        }
        else {
            setIsLoading(false)
        }
    }, [placeID, selectedRestaurant]);

    useEffect(() => {

        const query = getRestaurantAvailabilityQuery(lat, lng)
    
        const unsubscribe = onSnapshot(query, (querySnapshot) => {
            const availabilities = [];
            querySnapshot.forEach((doc) => {
                availabilities.push(doc);
            });
            availabilities.sort((a,b) => a.time - b.time)
            setAvailabilityList(availabilities)
            updateMapAvailabilities(availabilities)
        });

        return () => { unsubscribe() }
    }, [lat, lng]);
    useEffect(()=> {
        if(landingPage){
        ReactGA.event({
            category:'LANDING_PAGE',
            action: 'OPENED',
            label:'QR_OPEN',
            nonInteraction:true

        })
        }},[]
    );
    useEffect(() => {

        const filterAvailabilities = (availabilities) => {

            const filter = (availability) => {
                const { chairs, time, restaurantFilters, restaurantName, placeID } = availability.data()
        
                const hours = toHours(time)

                return (
                (!persons || (chairs && (chairs === parseInt(persons) || (chairs[0] <= persons && chairs[1] >= persons))))
                &&
                (!from || hours >= from)
                &&
                (!to || hours <= to )
                &&
                (!tags || tags.length === 0 || restaurantFilters.some(filter => tags.includes(filter)))
                &&
                (!restaurantNameFilter || restaurantName.toLowerCase().includes(restaurantNameFilter.toLowerCase()))
                )
            }
            
            const filteredAvailabilities = availabilities.filter((availability) => 
                filter(availability)
            )
        
            setFilteredAvailabilityList(filteredAvailabilities)
        
            return filteredAvailabilities
        }

        const filteredAvailabilities = filterAvailabilities(availabilityList)

        updateMapAvailabilities(filteredAvailabilities)

    }, [persons, from, to, tags, restaurantNameFilter, availabilityList]);

    if (!selectedMenu) {
        if (landingPage && !selectedMenu) {
            setSelectedMenu("LandingPage")
            shownMenu = landingPageMenu
        }
        else {
            setSelectedMenu("Availabilities")
            shownMenu = availabilityMenu
        }
    }
    else {
        if (selectedMenu === "Restaurants") {
            shownMenu = restaurantMenu
        }
        else if (selectedMenu === "LandingPage" && landingPage && selectedRestaurant) {
            shownMenu = landingPageMenu
        }
        else {
            shownMenu = availabilityMenu
        }
    }

    const updateMapAvailabilities = (availabilities) => {
        const mapAvailabilities = [];
        availabilities.reduce((res, availability) => {
            const restaurantID = availability.data().restaurantID
            if (!res[restaurantID]) {
              res[restaurantID] = { 
                restaurantID: restaurantID, 
                lat: availability.data().lat,
                lng: availability.data().lng,
                count: 0 
              };
              mapAvailabilities.push(res[restaurantID])
            }
            res[restaurantID].count += 1;
            return res;
          }, {});
    
          setMapAvailabilityList(mapAvailabilities)
      }

    const isSelected = (thisAvailability, selectedAvailability) => {
        if (!selectedAvailability) {
            return false
        }
        return thisAvailability.id === selectedAvailability.id
    }

    if (isLoading) {
        return null
    }

    return (
        <div className="Main-Menu">
            {/*
            <div className="Main-Menu-Left">
                <h3 className="Near-You">Near You</h3>
                <AvailabilityList availabilities={filteredAvailabilityList} isSelected={isSelected} selectedAvailability={selectedAvailability} onClick={setSelectedAvailability}/>
            </div>
            <div className="Main-Menu-Right">
                <img id={"App-logo-mobile"} alt={"app-logo-mobile"} src={yonoLogo}/>
                <div className="Main-Menu-Options">
            {
            !isSmallScreen ?<MainMenuOptionButton option={"Availabilities"} onClick={ () => setSelectedMenu("Availabilities")} isSelected={selectedMenu === "Availabilities"} />
                : null
            }
                    <MainMenuOptionButton option={"Restaurants"} onClick={ () => setSelectedMenu("Restaurants")} isSelected={selectedMenu === "Restaurants"}/>
                    { 
                        landingPage && selectedRestaurant
                        ?
                        <MainMenuOptionButton option={"Featured"} onClick={ () => setSelectedMenu("LandingPage")} isSelected={selectedMenu === "LandingPage"}/>
                        :
                        null
                    }
                </div>
                {shownMenu}
            </div>
            */}
            {shownMenu}
        </div>
    )
}

export default MainMenu