import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import ReservationList from './ReservationList';
import Reservation from './Reservation';
import { upcomingCustomerReservations, allCustomerReservations, customerReservationHistory } from '../../db/reservations';
import "../../styles/Reservations.css";
import "../../styles/Buttons.css";
const ReservationMenu = (props) => {
    const { onHide, show } = props

    const [selectedMenu, setSelectedMenu] = useState("PendingAndHistory")
    const [selectedReservation, setSelectedReservation] = useState(null)

    const showReservation = (reservation) => {
        setSelectedMenu("Reservation")
        setSelectedReservation(reservation)
    }

    let shownMenu = <ReservationList listFunction={upcomingCustomerReservations} showReservation={showReservation}/>
  
    if (selectedMenu === "History") {
      shownMenu = <ReservationList listFunction={customerReservationHistory}/>
    }
    if (selectedMenu === "PendingAndHistory") {
        shownMenu = <ReservationList listFunction={allCustomerReservations} showReservation={showReservation}/>
      }
    if (selectedMenu === "Reservation") {
        shownMenu = <Reservation reservation={selectedReservation} setSelectedMenu={setSelectedMenu} onHide={onHide}/>
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
               <h1> My Reservations </h1>
            </Modal.Header>
            <Modal.Body>
                <button className='neutralButton' onClick={ () => setSelectedMenu("Pending")}>Pending</button>
                <button className='neutralButton' onClick={ () => setSelectedMenu("History")}>History</button>
                <button className='neutralButton' onClick={ () => setSelectedMenu("PendingAndHistory")}>All</button>
                {shownMenu}
            </Modal.Body>
        </Modal>
    )
}

export default ReservationMenu