import { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { toHours, toDate } from '../../helpers/timeHandler';
import { formatFilters, formatChairs, chairsHasRange } from '../../helpers/availabilities';
import { useAppSelector } from '../../app/hooks';
import Form from 'react-bootstrap/Form'
//import AvailabilitySuccessPopup from './AvailabilitySuccessPopup';
import { book } from '../../db/availabilities'
import NumberField from '../generic/NumberField';
import { Col, Row } from 'react-bootstrap';

require('../../styles/Availabilities.scss')

const AvailabilityConfirmPopup = (props) => {

    const { show, onHide, availability, availabilityId } = props
    const { restaurantName, chairs, time, restaurantFilters,outsideSource } = availability

    const starttime = toHours(time)
    const startDate = toDate(time)

    const [ customerChairs, setCustomerChairs ] = useState(null)
    const [ showGuestConfirm, setShowGuestConfirm ] = useState(false)
    const reduxUser = useAppSelector((state) => state.user)

    const filters = formatFilters(restaurantFilters)
    const formattedChairs = formatChairs(chairs)
    const [bookingComplete, isBookingComplete] = useState(false);

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    
    const setBookingCompleteTrue =()=>{
        isBookingComplete(true)
    }

    const setBookingCompleteFalse =()=>{
        isBookingComplete(false)
    }
    
    useEffect(() => {
        let isMounted = true
        
        if (isMounted && chairsHasRange(chairs)) {
            setCustomerChairs(null)
            setShowGuestConfirm(true)
            setBookingCompleteFalse()
        }
        if (isMounted && reduxUser.user) {
            if(reduxUser.user.email) {
                setEmail(reduxUser.user.email)
            }
            if(reduxUser.user.firstName) {
                setFirstName(reduxUser.user.firstName)
            }
            if(reduxUser.user.lastName) {
                setLastName(reduxUser.user.lastName)
            }
            if(reduxUser.user.phoneNumber) {
                setPhoneNumber(reduxUser.user.phoneNumber)
            }
        }
        if (!chairsHasRange(chairs)) {
            setCustomerChairs(chairs)
        }

        return () => { isMounted = false }
    }, [chairs]);

    if (!show) {
        return null
    }

    const handleChangeChairs = (e) => {
        setCustomerChairs(e.target.value)
    }

    const confirmPressable = () => {
        if (!showGuestConfirm) {
            return true
        }

        if (!customerChairs) {
            return false
        }
        
        if (!chairsHasRange(chairs) || (chairs[0] <= customerChairs && customerChairs <= chairs[1])) {
            return true
        }
        return false
    }

    const bookAvailability = async () => {
        if (!reduxUser.user) {
            alert("Not logged in!")
            return
        }
        if (outsideSource && !phoneNumber.match(/^\+\d{12}$/)){
            alert("Please input phonenumber in correct format (+358..)")
            return
        } 
        if(outsideSource && !email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            alert("Please type email in correct format")
            return
        }
        if (!customerChairs) {
            alert("Please confirm number of guests")
            return
        }
        if (outsideSource && (!firstName || !lastName)) {
            alert("Please fill both name fields")
            return
        }
        if (chairsHasRange(chairs) && !(chairs[0] <= customerChairs && customerChairs <= chairs[1])) {
            alert("Invalid number of guests!")
            return
        }
        if (await book(availabilityId, availability, reduxUser.user, customerChairs, firstName, lastName, email, phoneNumber)) {
            console.log('booking done');
            setBookingCompleteTrue()
        }
    }

    return (
        <Modal show={show} onHide={onHide} className="Availability-confirm-popup" backdrop="static">
            <Modal.Header closeButton>
             <h1> {bookingComplete ? 'Booking complete! ✅': 'Confirm Booking' } </h1>
            </Modal.Header>
            <Modal.Body>
                <h1>{restaurantName}</h1>
               <h4> <p className='Cursive'>{filters}</p> </h4>
                <h3> 📆</h3> <h4><p>{startDate}</p></h4>
                <h3> ⌚️ </h3><h4><p>{starttime}</p></h4>
                <h3> {!bookingComplete ? 'Possible Guests': 'Guests' }</h3> <h4><p>{bookingComplete ? customerChairs :formattedChairs}</p></h4>

                {
                    showGuestConfirm && !bookingComplete
                    ?      
                    <NumberField title="Confirm number of guests*" min={chairs[0]} max={chairs[1]} onChange={handleChangeChairs} />
                    :
                    null
                }
                   {
                    !bookingComplete
                    ?      
                    <Form>
                    <h2>Booking information</h2>
                    <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>{outsideSource ? 'Email*':'Email (for booking confirmation)' }</Form.Label>
                    <Form.Control type="email" placeholder="Email" onChange={(event) => setEmail(event.target.value)} />
                  </Form.Group>
                
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formFirstName">
                    <Form.Label>{outsideSource ? 'Firstname*': 'Firstname (optional)' }</Form.Label>
                    <Form.Control type="text" placeholder="First Name" onChange={(event) => setFirstName(event.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formLastName">
                    <Form.Label>{outsideSource ? 'Last Name*': 'Last Name (optional)'}</Form.Label>
                    <Form.Control type="text" placeholder="Last Name" onChange={(event) => setLastName(event.target.value)} />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as = {Col} className="mb-3" controlId="formPhoneNumber">
                    <Form.Label>{outsideSource ? 'Phonenumber*' : 'Phonenumber (optional)' }</Form.Label>
                    <Form.Control className='input-sm' type="text" placeholder="+358..." onChange={(event) => setPhoneNumber(event.target.value)} />
                  </Form.Group>
                  <Form.Group as = {Col} className="mb-3" controlId="formPhoneNumber">

                  </Form.Group>
                  </Row>
                  </Form>
                    :
                    null
                }
       { !bookingComplete ?
                <button 
                    onClick={bookAvailability} 
                    className="bookButton"
                    disabled={!confirmPressable()}
                    style={ { opacity: confirmPressable() ? 1 : 0.3 }}
                >
                    <b>Book</b>
                </button>
            :<div>
            <h3>The table has been booked for you! </h3>
            <h3>Reservation info will be sent to your email shortly 📧 </h3>
            <h3>You may also view this in My Reservations ⬆️ </h3>
            </div>
            }
            </Modal.Body>

            {/* <AvailabilitySuccessPopup  isOpen={successModalIsOpen} data={props} onRequestClose={()=> setSuccessModalOpen(false)}/> */}
        </Modal>
        
    )
}

export default AvailabilityConfirmPopup;
