import { useState, useEffect } from 'react'
import restaurants from "../../db/restaurants"
import { useAppSelector } from '../../app/hooks';
import RestaurantDetailsPopup from './RestaurantDetailsPopup';
import { defaultLat, defaultLng } from '../../app/settings';
import RestaurantListItem from './RestaurantListItem';
//import { fabClasses } from '@mui/material';
import { populateExtraFields, sortRestaurantList } from '../../helpers/restaurants'

require('../../styles/Restaurants.css')

const RestaurantMenu = (props) => {

  let { restaurantNameFilter, availabilities } = props
  availabilities = availabilities.map((availability) => availability.data())

  const [restaurantList, setRestaurantList] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null)
  const [detailsModalIsOpen, setDetailsModalOpen] = useState(false);

  const reduxLocation = useAppSelector((state) => state.location)

  let userLat = null
  let userLng = null

  if (reduxLocation.location) {
    userLat = reduxLocation.location.lat
    userLng = reduxLocation.location.lng
  }
  
  useEffect(() => {
    let menuActive = true
    const lat = userLat ? userLat : defaultLat;
    const lng = userLng ? userLng : defaultLng;
    restaurants.getRestaurants(lat, lng).then((data) => {
      if (menuActive) {
        populateExtraFields(data, availabilities, userLat, userLng)
        sortRestaurantList(data)

        if (restaurantNameFilter) {
          data = data.filter((restaurant) => 
            restaurant.restaurantName.toLowerCase().includes(restaurantNameFilter.toLowerCase())
          )
        }
        
        setRestaurantList(data)
      }
    })
    return () => {
      menuActive = false
    }
  }, [userLat, userLng, restaurantNameFilter, availabilities]);

  const setDetailsModalTrue =()=>{
      setDetailsModalOpen(true)
  }
  const setDetailsModalFalse =()=>{
      setDetailsModalOpen(false)
  }

 /* 
  const isSelected = (thisRestaurant, selectedRestaurant) => {
    if (!selectedRestaurant) {
      return false
    }
    return thisRestaurant.place_id === selectedRestaurant.place_id
  }

  useEffect(() => {
      let unsubscribe = () => {}

      const query = getRestaurantAvailabilitiesbyPlaceIDQuery(item.place_id)

      unsubscribe = onSnapshot(query, (querySnapshot) => {
        const availabilities = [];
        querySnapshot.forEach((doc) => {
          availabilities.push(doc);
        });

        const mapAvailabilities = [];
        availabilities.reduce((res, availability) => {
          const restaurantID = availability.data().restaurantID
          if (!res[restaurantID]) {
            res[restaurantID] = { 
              restaurantID: restaurantID, 
              lat: availability.data().lat,
              lng: availability.data().lng,
              count: 0 
            };
            mapAvailabilities.push(res[restaurantID])
          }
          res[restaurantID].count += 1;
          return res;
        }, {});

        availabilities.sort((a,b) => a.time - b.time)

        setAvailabilityList(availabilities)
      });

      return () => { unsubscribe() }
    }
  
*/
  return (
    <div className="Main-menu-content">
      <div className="Restaurants">
        {restaurantList.map(restaurant => {
          return (
            <RestaurantListItem 
              key={restaurant.place_id} 
              item={restaurant} 
              onClick={() => {
                setSelectedRestaurant(restaurant);
                setDetailsModalTrue()
            }}/>
          )
        })}
      </div>
      
      {/*
      <Map userLat={userLat} userLng={userLng} selectedItem={selectedRestaurant}>
        {restaurantList.map((restaurant) => {
              return <MapRestaurant
                      key={`map_${restaurant.place_id}`} 
                      restaurant={restaurant}
                      lat={restaurant.lat}
                      lng={restaurant.lng}
                      onClick={() => {
                        setSelectedRestaurant(restaurant);
                        setDetailsModalTrue()
                      }}
                  />
          })}
      </Map>
        */}
      <RestaurantDetailsPopup isOpen={detailsModalIsOpen} restaurant={selectedRestaurant} onRequestClose={()=> setDetailsModalFalse()}/>
    </div>

  )
}

export default RestaurantMenu