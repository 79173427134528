import { createSlice } from '@reduxjs/toolkit'

const loadFromLocalStorage = () => {
  try {
    const stateStr = localStorage.getItem('user');
    return stateStr ? JSON.parse(stateStr) : null
  } catch (e) {
    console.error(e);
    return null
  }
};

const saveToLocalStorage = (state) => {
  try {
    localStorage.setItem('user', JSON.stringify(state));
  } catch (e) {
    console.error(e);
  }
};

const initialState = { user: loadFromLocalStorage() }

// https://react-redux.js.org/introduction/getting-started
export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload
      saveToLocalStorage(state.user)
    },
    logout: (state) => {
      state.user = null
      saveToLocalStorage(state.user)
    }
  },
})

// Action creators are generated for each case reducer function
export const { login, logout } = userSlice.actions

export default userSlice.reducer