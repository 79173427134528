import AvailabilityDetailsSmall from './AvailabilityDetailsSmall';

require('../../styles/Availabilities.scss')

const AvailabilityListItem = (props) => {

    const { availability, availabilityId, isSelected, onClick, openConfirmModal } = props

    let selectedStyle = ""

    if (isSelected) {
        selectedStyle = "selected"
    }

    return (
        <div className={`Availability-List-Item ${selectedStyle}`} onClick={onClick}>
            <AvailabilityDetailsSmall availabilityId={availabilityId} availability={availability} title={availability.restaurantName} openConfirmModal={openConfirmModal} />
        </div>
    )
}

export default AvailabilityListItem