import { collectionHandler } from '../db/collections'
import { getDoc, doc } from 'firebase/firestore'

export const getUser = async (email: string, collection: string) => {
    const userRef = doc(collectionHandler(collection),email.toLowerCase())
    const userSnap = await getDoc(userRef)
    return userSnap.data()
}

export const getUserStatistics = async (uid: string) => {
    const userRef = doc(collectionHandler("customerStatistics"),uid)
    const userSnap = await getDoc(userRef)
    return userSnap.data()
}