import '../styles/HeaderMenu.css';

const HeaderMenuItem = (props) => {
    const { children, onClick } = props

    return (
        <div className={"Header-menu-item"} onClick={onClick}>
            {children}
        </div>
    )
}

export default HeaderMenuItem