import { useEffect, useState } from 'react'
import MainMenu from './components/MainMenu'
import Header from './components/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.scss';
import { useAppSelector, useAppDispatch } from './app/hooks';
import LoginView from './components/login/LoginView';
import { hideLoginPopup } from './reducers/loginPopupSlice';
import { setLocation } from './reducers/locationSlice';
import { BrowserRouter, Route, Routes,useLocation } from "react-router-dom";

import AvailabilitySearch from './components/availabilities/AvailabilitySearch';
import PrivacyPolicy from './components/PrivacyPolicy';
//import QRCode from "react-qr-code";

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-183913708-2"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const defaultPlaceID = "ChIJkzgyqEwKkkYRXDBgyzVhq_w"

const yonoLogo = require('./assets/images/YONO_logo.png')

const App = () => {
  const showLoginPopup = useAppSelector((state) => state.showLoginPopup)
  const dispatch = useAppDispatch()
 
  const closeLoginModal = () => dispatch(hideLoginPopup())

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [name, setName] = useState("")
  const [filters, setFilters] = useState({
    persons: null,
    from: null,
    to: null,
    tags: null
  })
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1000px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = mediaQuery => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;

      dispatch(setLocation({lat: lat, lng: lng}))
    })
  }, [dispatch]);

  const updateFilters = (persons, from, to, tagList) => {
    setFilters({
      persons: persons,
      from: from,
      to: to,
      tags: tagList
    })
  }

  return (
    
    <div id="App">
      {/*<QRCode value="https://nomoqu-app.web.app?restaurantID=QfKDVWMq3BZqW9Tge2GWq6AyZQs2" />*/}
      {
      !isSmallScreen ? 
      <Header isSmallScreen={isSmallScreen} >
        {/*
        <AvailabilitySearch setName={setName} persons={filters.persons} from={filters.from} to={filters.to} tags={filters.tags} updateFilters={updateFilters}/>
        */}
      </Header> :
      <div id="App-logo-mobile-container">
        <img id={"App-logo"} alt={"app-logo"} src={yonoLogo}/>
      </div>
      }
      <LoginView show={showLoginPopup.show} onHide={closeLoginModal}/>
      <BrowserRouter>
        <Routes>
          <Route path="restaurants/:placeID" element={<MainMenu isSmallScreen={isSmallScreen} restaurantNameFilter={name} searchFilters={filters} landingPage={true} />}/>
          <Route path="*" element={<MainMenu isSmallScreen={isSmallScreen} restaurantNameFilter={name} searchFilters={filters} landingPage={true} placeID={defaultPlaceID}/>}/>
          <Route path="PrivacyPolicy" element={<PrivacyPolicy />}/>
           {/*
          <Route path="*" element={<MainMenu isSmallScreen={isSmallScreen} restaurantNameFilter={name} searchFilters={filters} landingPage={false}/>} />
          */}
        </Routes>
      </BrowserRouter>
      {
      isSmallScreen ? 
      <Header isSmallScreen={isSmallScreen} >
        {/*
        <AvailabilitySearch setName={setName} persons={filters.persons} from={filters.from} to={filters.to} tags={filters.tags} updateFilters={updateFilters}/>
        */}
      </Header> :
      null
      }
    </div>
  );
}

export default App;
