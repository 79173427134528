/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from "react";
import "../styles/Header.css";
import { CSSTransition } from "react-transition-group";
import PersonIcon from '@mui/icons-material/Person';
//import NotificationsIcon from '@mui/icons-material/Notifications';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DownloadIcon from '@mui/icons-material/Download';

import HeaderMenuItem from './HeaderMenuItem';
import { showLoginPopup } from '../reducers/loginPopupSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import ReservationMenu from './reservations/ReservationMenu';
import ProfileMenu from './users/ProfileMenu';
//import UserLocation from "./UserLocation";
//import { setLocation } from '../reducers/locationSlice';
//import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
const yonoLogo = require('../assets/images/YONO_logo.png')


const Header = (props) => {
  const { children, isSmallScreen } = props

  const dispatch = useAppDispatch()
  const reduxUser = useAppSelector((state) => state.user)

  const [showReservationModal, setShowReservationModal] = useState(false)
  const [showProfileModal, setShowProfileModal] = useState(false)

  const openLoginModal = () => dispatch(showLoginPopup())

  const openReservationModal = () => setShowReservationModal(true)
  const closeReservationModal = () => setShowReservationModal(false)

  const openProfileModal = () => setShowProfileModal(true)
  const closeProfileModal = () => setShowProfileModal(false)
 
  const reservationOnclick = () => {
      if (reduxUser.user) {
          openReservationModal()
      }
      else {
          openLoginModal()
      }
  }

  const profileOnclick = () => {
      if (reduxUser.user) {
          openProfileModal()
      }
      else {
          openLoginModal()
      }
  }

  //const reduxLocation = useAppSelector((state) => state.location)
  
  return (
    <header className="Header">
      <CSSTransition
        in={!isSmallScreen}
        timeout={350}
        classNames="NavAnimation"
      >
        <nav className="Nav">
          {/*
          <div>
            { children }
          </div>
          */}
          {!isSmallScreen ?
          <img id={"App-logo"} alt={"app-logo"} src={yonoLogo}/>
          : null
          }
            {/*
            <HeaderMenuItem onClick={reservationOnclick}>
                <EventAvailableIcon/>
                <p>My Reservations</p>
            </HeaderMenuItem>
            <HeaderMenuItem onClick={profileOnclick}>
                <PersonIcon/>
                <p>Profile</p>
            </HeaderMenuItem>
            */}
            {isSmallScreen ?
            <div className={"Header-Menu-Items"}>
            <HeaderMenuItem onClick={()=> window.open("https://onelink.to/u7th4d", "_blank")}>
                <DownloadIcon/>
                <p>Get The App</p>
            </HeaderMenuItem>
            </div>:null
            }
        </nav>
      </CSSTransition>
      <ReservationMenu show={showReservationModal} onHide={closeReservationModal}/>
      <ProfileMenu show={showProfileModal} onHide={closeProfileModal}/>
    </header>
  );
}
export default Header;