import { useAppSelector } from '../../app/hooks'
import { getDistanceKilometers } from "../../helpers/location";
import { useState } from 'react'
import RestaurantDetailsPopup from './RestaurantDetailsPopup';
import RestaurantFilters from './RestaurantFilters';
import Distance from '../generic/Distance';
import BookButton from '../availabilities/BookButton'
require('../../styles/Restaurants.css')

const restaurantIcon = require('../../assets/images/restaurants_on.png')

const RestaurantListItem = (props) => {
    const { item, onClick } = props
    const { restaurantName, lat, lng, logo, shortDescription, filters,hasAvailabilities } = item
  //  console.log(item);
    const [detailsModalIsOpen, setDetailsModalOpen] = useState(false);

    const reduxLocation = useAppSelector((state) => state.location)

    let distanceKilometers = null

    if (reduxLocation.location && lat && lng) {
        const userLat = reduxLocation.location.lat
        const userLng = reduxLocation.location.lng

        distanceKilometers = getDistanceKilometers(userLat,userLng,lat,lng)
    }

    const setDetailsModalFalse = () => {
        setDetailsModalOpen(false)
    }

    return (
        <div className={"Restaurant-list-item"} onClick={onClick}>
            <div className="row">
                <div className="column Restaurant-list-item-logo">
                    { 
                        logo
                        ?
                        (
                            <img 
                                src={`data:image/png;base64,${logo}`}
                                alt="restaurant-logo"
                            >
                            </img> 
                        )
                        :
                        (
                            <div className={"Restaurant-default-logo"}>
                                <img src={restaurantIcon} alt="restaurant-logo" />
                            </div>
                        )
                    }
                </div>
                <div className="column">
                    <h5>{restaurantName}</h5>
                   {filters? <i><RestaurantFilters filters={filters}/></i> :""}
                </div>
            </div>
            <div className="row">          
                <div className="column">
                    {""}
                </div>
                <div className="column">
                    <p>{shortDescription ? shortDescription : ""}</p> 
                </div>
                <div className="row"> 
                    <div className="column">
                        {hasAvailabilities?<h5 style ={{bottom:0}}>Availabilities Found!</h5>:<h5 style ={{bottom:0}}>No Availabilities!</h5> }
                        <BookButton text={"Book now!"} disabled={!hasAvailabilities} style={{fontSize: "16px"}}/>
                    </div>
                    <div className="column">
                        <Distance distance={distanceKilometers}/>
                    </div>
                </div>
            </div> 

            <RestaurantDetailsPopup  isOpen={detailsModalIsOpen} data={props} onRequestClose={()=> setDetailsModalFalse()}/>
        </div>
    )
}

export default RestaurantListItem